.pagination {
    margin-top: 45px;
    margin-bottom: 35px;
    /* display: flex; */
    /* list-style: none; */
    text-decoration: none;
    /* outline: none; */
    
}
    
.pagination>.active>a {
    background-color: #009ef7;
    color: #fff;
    text-decoration: none;
}

.pagination>li>a, .pagination>li>span {
    background-color: #fff;
    color: black !important;
    text-decoration: none;
    /* outline: none; */
    cursor: pointer;
}

.pagination>li>a {
    border: 1px solid #009ef7;
    padding: 5px 10px;
    /* outline: none; */
    cursor: pointer;
    text-decoration: none;

}

.pagination>li>a, .pagination>li>span {
    color: #009ef7;
    background-color: rgb(255, 255, 255);
}

.border-zk{
    border: 1px solid #c3c3c3;
}

.echarts-for-react {
    width: -webkit-fill-available;
}